
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { shiftDateWithTimezone, shiftHours } from '@/helpers/date-helper';
import { getOverviewUrlBySport } from '@/routing/sports';

import { SportCategory, SportLocale } from '@/enums/sports';

import { SportType } from '@/@types/graphql-types';
import { LocaleResponse } from '@/helpers/locale-helper';
import {
	GetSportsFooterEventsV2Document,
	GetSportsFooterEventsV2Query,
	GetSportsFooterEventsV2QueryVariables,
} from '@/pages/graphql/queries/GetSportsFooterV2.query';

import { COUNTRY_WITHOUT_TITLEDETAIL } from '@/constants/web-locales.constant';
import {
	GetTopCompetitionsDocument,
	GetTopCompetitionsQueryVariables,
} from '@/pages/graphql/queries/GetTopCompetitions.query';

const Language = namespace('language');

type SportsFooterEventsData = NonNullable<GetSportsFooterEventsV2Query['sportEventsV2']['edges']>;

const TOP_SPORTS = [
	SportType.Football,
	SportCategory.Motorsport,
	SportType.Tennis,
	SportType.AmericanFootball,
	SportType.Baseball,
] as const;

function getFullpath({ node }: SportsFooterEventsData[number]): string {
	if (node.__typename === 'MultiStepSportEvent') {
		return node.parent?.content?.fullPath ?? '';
	}

	return node.content?.fullPath ?? '';
}

function getEventname(event: SportsFooterEventsData[number]): string {
	if (event?.node?.__typename === 'MultiStepSportEvent') {
		return event?.node?.parent?.content?.name ?? '';
	}

	return event?.node?.competition?.content?.name ?? '';
}

@Component({
	name: 'SportsDefaultFooter',
	apollo: {
		liveData: {
			query: GetSportsFooterEventsV2Document,
			variables(): GetSportsFooterEventsV2QueryVariables {
				return this.liveDataQueryVariables;
			},
			update: data => data.sportEventsV2.edges ?? [],
		},
		upcomingData: {
			query: GetSportsFooterEventsV2Document,
			variables(): GetSportsFooterEventsV2QueryVariables {
				return this.upcomingDataQueryVariables;
			},
			update: data => data.sportEventsV2.edges ?? [],
		},
		topCompetitionsData: {
			query: GetTopCompetitionsDocument,
			variables(): GetTopCompetitionsQueryVariables {
				return this.topCompetitionsQueryVariables;
			},
			update: data => data.sportCompetitionsV2.edges ?? [],
		},
	},
})
export default class SportsDefaultFooter extends Vue {
	@Language.Getter country: string;
	@Language.Getter language: string;
	@Language.Getter localeObject: LocaleResponse;
	@Language.State webLocale: SportLocale;

	currentYear = new Date().getFullYear();

	get version() {
		return process.env.VERSION;
	}

	get baseQueryVariables(): GetSportsFooterEventsV2QueryVariables {
		const { language, country } = this;
		// we filter out events that don't have a `getFullPath`.
		// shortcut: it'd be cleaner if the backend added a hasFullPath filter parameter, but we gotta move fast :sonic:.
		// plus, 1) the request itself only increases by 1kb (gzipped) and 2) it's only happening in sports, not on title detail pages.
		const first = 50;
		return { language, country, first, timezoneOffset: 0 };
	}

	get footerSections() {
		return [
			{
				title: this.$t('SPORTS_WEBAPP_TOP_X_LIVE_EVENTS', { x: this.liveEvents.length }),
				links: this.liveEvents,
			},
			{
				title: this.$t('SPORTS_WEBAPP_TOP_X_UPCOMING_EVENTS', { x: this.upcomingEvents.length }),
				links: this.upcomingEvents,
			},
			{
				title: this.$t('SPORTS_WEBAPP_TOP_X_COMPETITIONS', { x: this.topCompetitions.length }),
				links: this.topCompetitions,
			},
			{
				title: this.$t('SPORTS_WEBAPP_TOP_X_SPORTS', { x: this.topSports.length }),
				links: this.topSports,
			},
		];
	}

	get titleUrlsEnabled() {
		return !COUNTRY_WITHOUT_TITLEDETAIL.includes(this.webLocale);
	}

	// LIVE EVENTS
	liveData: SportsFooterEventsData = [];

	get liveDataQueryVariables() {
		const date = new Date();
		date.setUTCMinutes(0, 0, 0);

		return {
			...this.baseQueryVariables,
			filter: { startAt: { from: shiftHours(date, -2).toISOString() } },
		};
	}

	get liveEvents() {
		return this.liveData
			.map(event => ({
				text: `${getEventname(event)}: ${event?.node?.content?.name}`,
				path: getFullpath(event),
			}))
			.filter(event => event.path !== '')
			.slice(0, 5);
	}

	// UPCOMING EVENTS
	upcomingData: SportsFooterEventsData = [];

	get upcomingDataQueryVariables() {
		return {
			...this.baseQueryVariables,
			filter: { startAt: { from: shiftDateWithTimezone(this.localeObject.timezone, 1).toISOString() } },
		};
	}

	get upcomingEvents() {
		return this.upcomingData
			.map(event => ({
				text: `${getEventname(event)}: ${event?.node?.content?.name}`,
				path: getFullpath(event),
			}))
			.filter(event => event.path !== '')
			.slice(0, 5);
	}

	// TOP COMPETITIONS
	topCompetitionsData: SportsFooterEventsData = [];

	get topCompetitionsQueryVariables(): GetTopCompetitionsQueryVariables {
		const today = new Date();
		today.setUTCHours(0, 0, 0, 0);

		return {
			country: this.country,
			language: this.language,
			first: 5,
			after: '',
			filter: {
				activeOn: today.toISOString(),
			},
		};
	}

	get topCompetitions() {
		return this.topCompetitionsData
			.map(competition => ({
				text: competition?.node?.content?.name,
				path: competition?.node?.content?.fullPath,
			}))
			.filter(event => event.path !== '');
	}

	// TOP SPORTS
	get topSports() {
		return TOP_SPORTS.map(sport => ({
			text: this.$tc(`SPORTS_WEBAPP_SPORT_TYPE_${sport}`),
			path: getOverviewUrlBySport(sport, this.webLocale),
		})).filter(event => event.path !== '');
	}
}
